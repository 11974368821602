<template>
  <div class="meeting-detail">
    <div class="anonymous-user">
      <div class="icon">
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 13.0625C12.7188 13.0625 12.1027 13.75 10 13.75C7.89732 13.75 7.28571 13.0625 6 13.0625C2.6875 13.0625 0 15.6492 0 18.8375V19.9375C0 21.0762 0.959821 22 2.14286 22H17.8571C19.0402 22 20 21.0762 20 19.9375V18.8375C20 15.6492 17.3125 13.0625 14 13.0625ZM10 12.375C13.5491 12.375 16.4286 9.60352 16.4286 6.1875C16.4286 2.77148 13.5491 0 10 0C6.45089 0 3.57143 2.77148 3.57143 6.1875C3.57143 9.60352 6.45089 12.375 10 12.375Z" fill="#5A72DC"/>
        </svg>
      </div>
      <div>
        <div v-if="!isBoostPack" class="spacing-bottom">
          <strong>{{ name }}</strong>
          <p>Utilisateur anonyme</p>
          <p>{{ company }}</p>
        </div>
        <div v-else class="spacing-bottom">
          <strong>{{ name }}</strong>
          <p>{{ email }}</p>
          <p>{{ company }}</p>
        </div>
        <router-link v-if="!isBoostPack"
            :to="{ name: 'CoachMeetingAnonymousHistory', params: { id: anonymousUserIdentifier }}">
          Voir les derniers rendez-vous
        </router-link>
        <router-link v-else :to="{ name: 'BoostPackMeetingList', params: { id: meeting.boost_pack.id }}">
          Voir les derniers rendez-vous
        </router-link>
      </div>
    </div>
    <div>
      <div class="spacing-bottom">
        <strong>Date et heure</strong>
        <p>{{ formatedDate }}</p>
      </div>
      <div>
        <strong>Numéro de téléphone</strong>
        <p>{{ formatedPhone }}<br />Numéro de salle : {{ formatedRoomNumber }}</p>
        <p>Mot de passe coach : <strong>36008</strong></p>
        <p></p>
      </div>
    </div>
    <div class="boost-pack-infos" v-if="isBoostPack">
      <span>Avancé pack :</span>
      <p>Coaching {{ meeting.boost_pack.boost_consumption }}/{{ meeting.boost_pack.boost_quantity }}</p>
      <span>Tripartite de début:</span><p>{{ tripartiteStart }}</p>
      <span>Tripartite de fin:</span><p>{{ tripartiteEnd }}</p>
    </div>
  </div>
  <div class="cancel">
    <a class="spacing-bottom" href="#" @click.prevent="cancelMeeting">Annuler le rendez-vous</a>
    <a class="spacing-bottom" href="#" @click.prevent="notShowUpMeeting">Ne s'est pas présenté</a>
  </div>
</template>

<script>
import moment from "moment";
import meetingsService from "@/services/meetings.service";
import Toastify from "toastify-js";

export default {
  name: "MeetingDetail",
  props: {
    meeting: {type: Object, default: null}
  },
  methods: {
    cancelMeeting() {
      meetingsService.cancelMeeting(this.meeting.id)
          .then(() => {
            this.$router.push({name: 'Coaching' })
            Toastify({
              text: "Rendez-vous annulé",
              className: "success",
            }).showToast();
          });
    },
    notShowUpMeeting() {
      meetingsService.notShowUpMeeting(this.meeting.id)
          .then(() => {
            this.$router.push({name: 'Coaching' })
            Toastify({
              text: "Rendez-vous annulé",
              className: "success",
            }).showToast();
          });
    }
  },
  computed: {
    isBoostPack() {
      return (this.meeting && this.meeting.boost_pack)
    },
    anonymousUserIdentifier() {
      return (this.meeting && typeof this.meeting.anonymous_user !== 'undefined') ? this.meeting.anonymous_user.identifier : "id";
    },
    formatedDate() {
      return (this.meeting) ? moment(this.meeting.starts_at).format('dddd Do MMMM à HH:mm').toLowerCase() : "";
    },
    formatedPhone() {
      return (this.meeting) ? this.meeting.phone_number : "";
    },
    formatedRoomNumber() {
      return (this.meeting) ? this.meeting.room_number : "";
    },
    name() {
      return (this.isBoostPack) ? this.meeting.boost_pack.user.first_name + ' ' + this.meeting.boost_pack.user.last_name : (this.meeting ? this.meeting.anonymous_user.first_name : '');
    },
    email() {
      return (this.isBoostPack) ? this.meeting.boost_pack.user.email : "";
    },
    company() {
      return (this.isBoostPack && this.isBoostPack) ? this.meeting.boost_pack.user.company.name : (this.meeting ? this.meeting.anonymous_user.company.name : '');
    },
    tripartiteStart() {
      return (this.meeting.boost_pack.tripartite_at_start) ? 'Oui': 'Non'
    },
    tripartiteEnd() {
      return (this.meeting.boost_pack.tripartite_at_end) ? 'Oui': 'Non'
    }
  }
}
</script>
